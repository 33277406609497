@font-face {
  font-family: Graphik;
  src: url("Graphik-Regular.4505e242.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-RegularItalic.72ab1423.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Medium.39451fa4.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-MediumItalic.9657d2a1.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Semibold.9c496a4e.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-SemiboldItalic.5a110c21.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-Bold.6c9394fe.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Graphik;
  src: url("Graphik-BoldItalic.57d3769b.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
/*# sourceMappingURL=index.bbf9472f.css.map */
