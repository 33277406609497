@font-face {
  font-family: "Graphik";
  src: url("./../fonts/graphik/Graphik-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("./../fonts/graphik/Graphik-RegularItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("./../fonts/graphik/Graphik-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("./../fonts/graphik/Graphik-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("./../fonts/graphik/Graphik-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("./../fonts/graphik/Graphik-SemiboldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Graphik";
  src: url("./../fonts/graphik/Graphik-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("./../fonts/graphik/Graphik-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
